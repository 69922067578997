import { IQueryParams } from './request.model';

export enum ECountry {
  UNITED_STATES = 'UNITED_STATES',
  CANADA = 'CANADA'
}
export type Country = ECountry.UNITED_STATES | ECountry.CANADA;

export interface ILocationDTO {
  country: string;
  province: string;
  city: string;
  postalCode: string;
}

export enum ELocationField {
  country = 'COUNTRY',
  province = 'PROVINCE',
  city = 'CITY',
  postalCode = 'POSTAL_CODE'
}

export interface ILocationQueryParamsDTO extends IQueryParams {
  field: ELocationField;
  city?: string;
  country?: string;
  postalCode?: string;
  province?: string;
}

export enum EProvinceCanada {
  'Alberta' = 'AB',
  'British Columbia' = 'BC',
  'Manitoba' = 'MB',
  'New Brunswick' = 'NB',
  'Newfoundland and Labrador' = 'NL',
  'Nova Scotia' = 'NS',
  'Northwest Territories' = 'NT',
  'Nunavut' = 'NU',
  'Ontario' = 'ON',
  'Prince Edward Island' = 'PE',
  'Québec' = 'QC',
  'Saskatchewan' = 'SK',
  'Yukon' = 'YT'
}

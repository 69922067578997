<mk-layout-wrapper>
    <header></header>

    <left-menu></left-menu>

    <mk-layout-content>
        <router-outlet></router-outlet>
    </mk-layout-content>

    <footer></footer>
</mk-layout-wrapper>
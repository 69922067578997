import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export class HandleDoubleRequestAbstractClass {
  private lockLinks: string[] = [];
  private _subject = new Subject<any>();

  constructor() {
    this._subject.complete();
  }

  public checkDoubleRequest<T>(link: string, observable: Observable<T>): Observable<T> {
    if (!this.lockLinks.some(_ => _ === link)) {
      this.lockLinks.push(link);
      return observable.pipe(
        tap(_ => this.filter(link)),
        catchError(err => {
          this.filter(link);
          return throwError(err);
        }));
    } else {
      return this._subject;
    }
  }

  private filter(link: string) {
    this.lockLinks = this.lockLinks.filter(_ => _ !== link);
  }
}

// ** dateFrom: iso 8601 */
import {HttpParams} from '@angular/common/http';

export interface IQueryParams {
    sort?: string | string[];
    page?: number;
    size?: number;
    search?: string;
}

export enum ESort {
    ASC = 'ASC',
    DESC = 'DESC'
}

export const QPConst = {
    totalEl: 2000,
    size: 15
};

export type TListRequestFilterValue = number | string | boolean | undefined;

export interface IListRequest {
    sort?: string;
    filter?: IListRequestFilter;
}

export interface IPagedListRequest extends IListRequest {
    page?: number;
    size?: number;
}

export type IListRequestFilter<R extends IListRequest = IListRequest> = {
    [key: string]: TListRequestFilterValue;
} & { [key in keyof R]: never }

export interface IKeywordFilter {
    keyword?: string;
}

export const DEFAULT_PAGE_SIZE = 15;
export const MAX_PAGE_SIZE = 2000;

export function toHttpParams(request: { [key: string]: any }, skipEmptyStrings = true): HttpParams {
    return Object.keys(request).reduce(
        (agg, key) => {
            const value = request[key];
            if (value === null || typeof value === 'undefined') {
                return agg;
            } else if (skipEmptyStrings && typeof value === 'string' && !value.trim()) {
                return agg;
            } else if (Array.isArray(value)) {
                return value.reduce((arrAgg, arrValue) => arrAgg.append(key, arrValue), agg);
            } else {
                return agg.append(key, value);
            }
        },
        new HttpParams()
    );
}

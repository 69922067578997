import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, from } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthService } from '../services';
import { ETokenUrl } from 'app/shared/models';
import * as moment from 'moment-timezone';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshing: boolean;

  constructor(private authService: AuthService) {
  }

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    request = this.addHeadres(request);
    try {
      if (this.authService.token && !request.url.includes(ETokenUrl.REFRESH)) {
        const time = JSON.parse(atob(this.authService.token.split('.')[1])).exp * 1000 - new Date().getTime() - 100;
        if (time <= 0) return this.handle401Error(request, next);
      }
    } catch (error) {
      console.warn(error);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.url.includes(ETokenUrl.REFRESH)) {
          if (error.url.includes(ETokenUrl.REFRESH) || !this.authService.token) {
            this.logout();
          } else {
            return this.handle401Error(request, next);
          }
        }
        if (error.status === 403 && !error.url.includes(ETokenUrl.TOKEN)) this.logout();
        return throwError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshing) {
      this.refreshing = true;
      this.refreshTokenSubject.next(null);
      return from(this.authService.refresh())
        .pipe(
          switchMap(() => {
            this.refreshing = false;
            this.refreshTokenSubject.next(this.authService.token);
            return next.handle(this.addHeadres(request));
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(_ => next.handle(this.addHeadres(request)))
      );
    }
  }

  private logout() {
    this.refreshing = false;
    this.authService.logout();
  }

  private addHeadres(request: HttpRequest<any>) {
    request = request.clone({
      headers: request.headers.set('Cache-Control', 'no-cache, no-store, max-age=0, must-revalidate')
    });

      const zone = moment.tz.guess();
      if (zone) {
          request = request.clone({
              headers: request.headers.set('X-Timezone', zone)
          });
      }

      if (this.authService.token && !request.url.includes(ETokenUrl.TOKEN) && !request.url.includes(ETokenUrl.REFRESH)) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.authService.token}`
        }
      });
    }
    return request;
  }
}


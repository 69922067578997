import { LayoutState } from 'angular-admin-lte';

export const adminLteConf: LayoutState = {
  skin: 'blue',
  isSidebarLeftCollapsed: true,
  sidebarLeftMenu: [
    {
      label: 'Merchant Management',
      iconClasses: 'fa fa-user-circle-o',
      route: '/merchant-management'
    }, {
      label: 'Operations Management',
      iconClasses: 'fa fa-cogs',
      route: '/operations-management'
    },
    {
      label: 'Billing and Finance',
      iconClasses: 'fa fa-money',
      route: '/billing-finance'
    },
    {
      label: 'User Management',
      iconClasses: 'fa fa-users',
      route: '/user-management'
    }
  ]
};

import { ISelectList } from './base-entity.model';

export interface ILogin {
  password: string;
  username: string;
}

export enum ELocalStorage {
  REFRESH_TOKEN = 'refreshToken',
  TOKEN = 'token',
  REMEMBER_ME = 'REMEMBER_ME'
}

export interface ITokenResponse {
  refreshToken: string;
  token: string;
}

export enum ETokenUrl {
  REFRESH = 'refresh',
  TOKEN = 'token',
}

export enum ERole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_CASHIER = 'ROLE_CASHIER'
}

export const ROLES: ISelectList<ERole>[] = [
  {
    id: ERole.ROLE_ADMIN,
    valueUi: 'Admin'
  },
  {
    id: ERole.ROLE_MANAGER,
    valueUi: 'Manager'
  }, {
    id: ERole.ROLE_CASHIER,
    valueUi: 'Cashier'
  }
];

export interface IChangePasswordRequest {
  password: string;
  token: string;
}

export interface IEmailRequestBody {
  email: string;
}

export interface IUserDTO {
  active: boolean;
  email: string;
  id: number;
  registrationDate: string;
}

export interface IResetPassword {
  password: string;
  confirm: string;
}

export interface IUpdateUserPassword {
  id: number;
  newPassword: string;
  oldPassword: string;
}
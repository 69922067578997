
import { IBaseEntity, IExistingEntity, IHasId, ISelectList, StringCapitalizer } from './base-entity.model';
import { IBillingLocationDTO } from './billing-location.model';
import { Row } from './form.model';
import { Country } from './location.model';
import { TimeZone } from './moc';
import { IKeywordFilter, IListRequestFilter, IPagedListRequest } from './request.model';
import { DayOfWeek, ITimeRange } from './week.models';

export interface IMerchantListEntity extends IExistingEntity, IHasId {
  storeName: string;
  tenantId: string;
  type: EOrganization;
}

export interface IMerchant extends IBaseEntity, IHasId {
  description: string;
  email: string;
  storeName: string;
  tenantId: string;
  type: EOrganization;
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  address3: string;
  postalCode: string;
  city: string;
  province: string;
  country: Country;
  timeZone: string;
  billingLocationId?: number | string | IBillingLocationDTO;
  billingLocation?: IBillingLocationDTO;
  phoneNumber: string;
  fax: string;
  status: string;
  code: string;
  workingHours: IMerchantWorkingHours;
  contacts: IMerchantContact[];
  active: boolean;
  deleted: boolean;
  revenueShare: number;
  monthlyContractedAmount: number;
  defaultClassifications?: boolean;
}

export interface IMerchantListFilter extends IListRequestFilter<IPagedListRequest>, IKeywordFilter {
  active?: boolean;
  deleted?: boolean;
  status?: EMerchantStatus;
}

export type EMerchantStatus = 'ACTIVE' | 'SUSPENDED' | 'INACTIVE';

export enum EOrganization {
  'PHARMACY' = 'PHARMACY',
  'GROCERY' = 'GROCERY'
}

export const Organizations: ISelectList<EOrganization>[] = Object.keys(EOrganization)
  .map((_: EOrganization) => {
    return {
      valueUi: _.charAt(0) + _.slice(1).toLowerCase(),
      id: _
    };
  });

export enum EContactInfo {
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  FAX = 'fax'
}

export enum EContactNames {
  owner, billing, technical
}

export interface IContactDetails {
  name?: string;
  email?: string;
  phone?: string;
  fax?: string;
}

export type MerchantContactType = 'OWNER' | 'BILLING' | 'TECHNICAL';

export interface IMerchantContact extends IContactDetails {
  type: MerchantContactType;
}

export type IMerchantWorkingHours = {
  [dayOfWeek in DayOfWeek]?: ITimeRange;
};

export function ParseCountryName(country): string {
  return country && country.split('_').map(_ => StringCapitalizer(_)).join(' ');
}

export const MerchantFormSchemePreview: Row<IMerchant>[] = [{
  title: 'Store Configuration',
  fields: [{
    labelText: 'Address',
    name: 'address',
  }, {
    labelText: 'Address 2',
    name: 'address2',
  }, {
    labelText: 'Address 3',
    name: 'address3',
  }, {
    labelText: 'Country',
    name: 'country',
    format: ParseCountryName
  }, {
    labelText: 'Province',
    name: 'province',
  }, {
    labelText: 'City',
    name: 'city',
  }, {
    labelText: 'Postal Code',
    name: 'postalCode',
  }, {
    labelText: 'Phone',
    name: 'phoneNumber'
  }, {
    labelText: 'Fax',
    name: 'fax'
  }, {
    labelText: 'Time Zone',
    name: 'timeZone',
    format: text => TimeZone.find(_ => _.id === text)?.valueUi || text as string
  }, {
    labelText: 'Tax Location',
    name: 'billingLocationId',
  }]
}];


export const MerchantFunctionFormSchemePreview: Row<IMerchant>[] = [{
  title: 'Account Configuration',
  fields: [{
    labelText: 'Account Code',
    name: 'tenantId'
  }, {
    labelText: 'Business Name',
    name: 'storeName'
  }]
}, {
  title: 'Store Configuration',
  fields: [{
    labelText: 'Address',
    name: 'address'
  }, {
    labelText: 'Address 2',
    name: 'address2'
  }, {
    labelText: 'Address 3',
    name: 'address3'
  }, {
    labelText: 'Country',
    name: 'country',
    format: ParseCountryName
  }, {
    labelText: 'Province',
    name: 'province',
  }, {
    labelText: 'City',
    name: 'city',
  }, {
    labelText: 'Postal Code',
    name: 'postalCode'
  }, {
    labelText: 'Phone',
    name: 'phoneNumber'
  }, {
    labelText: 'Fax',
    name: 'fax'
  }, {
    labelText: 'Time Zone',
    name: 'timeZone',
    format: text => TimeZone.find(_ => _.id === text)?.valueUi || text as string
  }, {
    labelText: 'Tax Location',
    name: 'billingLocationId'
  }]
}];

export interface IMerchantUserDTO {
  active: boolean;
  id: number;
  username: string;
}

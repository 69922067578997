import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NonAuthGuard } from './core/guards/non-auth.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { BaseComponent } from './shared/components/base/base.component';
import { AdminGuard } from './core/guards/admin.guard';


const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/merchant-management',
        pathMatch: 'full'
      },
      {
        path: 'merchant-management',
        loadChildren: () => import('./pages/merchant-management/merchant-management.module').then(m => m.MerchantManagementModule)
      },
      {
        path: 'operations-management',
        loadChildren: () => import('./pages/operations-management/operations-management.module').then(m => m.OperationsManagementModule)
      },
      {
        path: 'billing-finance',
        loadChildren: () => import('./pages/billing-finance/billing-finance.module').then(m => m.BillingFinanceModule)
      },
      {
        path: 'user-management',
        loadChildren: () => import('./pages/user-management/user-management.module').then(m => m.UserManagementModule),
        canActivate: [AdminGuard]
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate: [NonAuthGuard]
  }, {
    path: '**',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate: [NonAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

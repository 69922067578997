import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

export const DateFormat = 'DD MMM YYYY';
export const DateTimeFormat = 'MMM DD/YYYY HH:mm:ss';
export const DateTimeFormatWithDayOfWeek = '(ddd) MMM DD/YYYY HH:mm:ss';
export const ServerDateFormat = 'YYYY-MM-DD';
export const TableDateFormat = 'MMM D, YYYY hh:mm a';

export interface IBaseDatePickerConfig extends Partial<BsDatepickerConfig> {
  isAnimated: boolean;
  dateInputFormat: string;
  containerClass: string;
  adaptivePosition?: boolean;
  showWeekNumbers: boolean;
}

export function GetBaseDatePickerConfig(): IBaseDatePickerConfig {
  return {
    isAnimated: true,
    dateInputFormat: DateFormat,
    containerClass: 'theme-dark-blue',
    adaptivePosition: true,
    showWeekNumbers: false
  };
}

export function FormatingDates<T>(list: T[], keyofData: keyof T, format = DateFormat) {
  list.forEach((item: T) => {
    const date = item[keyofData];
    if (date) {
      item[keyofData] = FormatingDate(date.toString(), format) as unknown as T[keyof T];
    }
  });
}

export function FormatingDate(value: string | Date, format = DateFormat) {
  return moment.utc(value).local().format(format);
}

export function FormatingDateToISO(value: string | Date) {
  return `${moment(value).format(ServerDateFormat)}T00:00:00.000Z`;
}

export function FormatingDateTime(value: string | Date) {
  return moment.utc(value).local().format(DateTimeFormat);
}



export enum EFormElement {
  TEXTAREA = 'TEXTAREA',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  SELECT = 'SELECT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  LOCATION = 'LOCATION',
  FILE = 'FILE',
  TOGGLE = 'TOGGLE'
}

export interface IFormOptions<T> {
  titleForm?: string;
  data?: T | T[];
  rows: Array<Row<T>>;
  cancel?: {
    btnText?: string;
    fn: () => void;
  };
  save?: {
    btnText?: string;
    fn: (data: T) => void;
  };
}

export interface IModalEditFormOptions<T> {
  data: T;
  rows: Array<Row<T>>;
  cansel?: {
    btnText?: string;
    fn: () => void;
  };
  save?: {
    btnText?: string;
    fn: (data: T) => void;
  };
}

export type TField<T> = IElementInput<T> | IElementBox<T> | IElementSelect<T> | IFileUpload<T>;

export interface IInputMain {
  isRequired?: boolean;
  disabled?: boolean;
  isReadonly?: boolean;
  isFocus?: boolean;
}

export interface Row<T> {
  title?: string;
  fields:  Array<TField<T>>;
}

export interface IElementInput<T> extends IInputMain {
  labelText: string;
  name: keyof T;
  hasDependency?: string;
  type?: EInputType;
  pattern?: EFieldPattern;
  isFullWidth?: boolean;
  hasPlus?: boolean;
  hasMinus?: boolean;
  elementType?: EFormElement;
  classes?: string | string[];
  format?: (value: string | number) => string;
}

export interface IElementBox<T> extends IInputMain {
  name: keyof T;
  elementType: EFormElement;
  left?: boolean;
  list: IBox[];
  classes?: string | string[];
  format?: (value: string | number) => string;
}

export interface IBox extends IInputMain {
  value: string | boolean;
  labelText: string;
}

export interface IElementSelect<T> extends IInputMain{
  labelText: string;
  elementType: EFormElement;
  name: keyof T;
  list: any[];
  keyOf: string;
  multiple?: boolean;
  classes?: string | string[];
  format?: (value: string | number) => string;
}

export interface IFileUpload<T> extends IInputMain {
  labelText: string;
  helpText: string;
  elementType: EFormElement.FILE;
  name: keyof T;
  isMultiple?: boolean;
  pattern?: string | string[];
  classes?: string | string[];
  format?: (value: string | number) => string;
}

export enum EFieldPattern {
  WEBSITE = '^[(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?',
  EMAIL = '^([\\w\\+-]+(?:\\.[\\w\\+-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-zA-Z]{2,17}(?:\\.[a-zA-Z]{2,17})?)$',
  PHONE = '^[0-9\\-\\+\\s\\(\\)]*$',
  DIGITAL = '[0-9]*',
  FLOAT_2D = '^[0-9]*(\\.[0-9]{1,2})?$',
  FLOAT_3D = '^[0-9]*(\\.[0-9]{1,3})?$',
  FLOAT_4D = '^[0-9]*(\\.[0-9]{1,4})?$',
  NEGATIVE_FLOAT = '^\-?[0-9]*(\\.[0-9]{1,2})?$',
  PERCENTS_2D = '^\-?[0-9]*(\\.[0-9]{1,2})?[%]$',
  ALPHANUMERIC = '[a-zA-Z0-9]+',
  WITHOUT_SPACE = '\\S*'
}

export const MIME_TYPE = {
  CSV: ['text/csv',
  'text/plain',
  'application/csv',
  'text/comma-separated-values',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
  'text/anytext',
  'application/octet-stream',
  'application/txt']
};

export enum EInputType {
  EMAIL = 'email',
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password'
}


import * as moment from 'moment';

export enum EDateRangePresets {
  'TODAY' = 'Today',
  'THIS_WEEK' = 'Week',
  'THIS_MONTH' = 'This Month',
  'LAST_MONTH' = 'Last Month',
  'LAST_3_MONTH' = 'Last 3 Month',
  'THIS_YEAR' = 'This Year',
  'LAST_YEAR' = 'Last Year'
}

export function dateRangeParams(value: EDateRangePresets): { from: Date, to: Date } | null {

  switch (value) {

    case EDateRangePresets.TODAY:
      return {
        from: moment().toDate(),
        to: moment().toDate()
      };

    case EDateRangePresets.THIS_WEEK:
      return {
        from: moment().startOf('week').toDate(),
        to: moment().toDate()
      };

    case EDateRangePresets.THIS_MONTH:
      return {
        from: moment().startOf('month').toDate(),
        to: moment().toDate()
      };

    case EDateRangePresets.LAST_MONTH:
      return {
        from: moment().subtract(1, 'months').startOf('month').toDate(),
        to: moment().subtract(1, 'months').endOf('month').toDate()
      };

    case EDateRangePresets.LAST_3_MONTH:
      return {
        from: moment().subtract(2, 'months').startOf('month').toDate(),
        to: moment().toDate()
      };

    case EDateRangePresets.THIS_YEAR:
      return {
        from: moment().startOf('year').toDate(),
        to: moment().toDate()
      };

    case EDateRangePresets.LAST_YEAR:
      return {
        from: moment().subtract(1, 'year').startOf('year').toDate(),
        to: moment().subtract(1, 'year').endOf('year').toDate()
      };

    default: console.error('Unsupport date preset');

  }
}

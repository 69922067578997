import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/services';

@Injectable({
  providedIn: 'root'
})

export class NonAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService) {
  }

  public canActivate():
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !this.authService.token || this.router.createUrlTree(['/merchant-management']);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BpPreviewComponent } from './bp-preview.component';


@NgModule({
  declarations: [BpPreviewComponent],
  imports: [
    CommonModule,
  ],
  exports: [BpPreviewComponent]
})
export class BpPreviewModule { }

export const TimeZone = [
    {id: 'Etc/GMT+12', valueUi: '(GMT-12:00) International Date Line West'},
    {id: 'Pacific/Midway', valueUi: '(GMT-11:00) Midway Island, Samoa'},
    {id: 'Pacific/Honolulu', valueUi: '(GMT-10:00) Hawaii'},
    {id: 'US/Alaska', valueUi: '(GMT-09:00) Alaska'},
    {id: 'America/Los_Angeles', valueUi: '(GMT-08:00) Pacific Time (US & Canada)'},
    {id: 'America/Tijuana', valueUi: '(GMT-08:00) Tijuana, Baja California'},
    {id: 'US/Arizona', valueUi: '(GMT-07:00) Arizona'},
    {id: 'America/Chihuahua', valueUi: '(GMT-07:00) Chihuahua, La Paz, Mazatlan'},
    {id: 'US/Mountain', valueUi: '(GMT-07:00) Mountain Time (US & Canada)'},
    {id: 'America/Managua', valueUi: '(GMT-06:00) Central America'},
    {id: 'US/Central', valueUi: '(GMT-06:00) Central Time (US & Canada)'},
    {id: 'America/Mexico_City', valueUi: '(GMT-06:00) Guadalajara, Mexico City, Monterrey'},
    {id: 'Canada/Saskatchewan', valueUi: '(GMT-06:00) Saskatchewan'},
    {id: 'America/Bogota', valueUi: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco'},
    {id: 'US/Eastern', valueUi: '(GMT-05:00) Eastern Time (US & Canada)'},
    {id: 'US/East-Indiana', valueUi: '(GMT-05:00) Indiana (East)'},
    {id: 'Canada/Atlantic', valueUi: '(GMT-04:00) Atlantic Time (Canada)'},
    {id: 'America/Caracas', valueUi: '(GMT-04:00) Caracas, La Paz'},
    {id: 'America/Manaus', valueUi: '(GMT-04:00) Manaus'},
    {id: 'America/Santiago', valueUi: '(GMT-04:00) Santiago'},
    {id: 'Canada/Newfoundland', valueUi: '(GMT-03:30) Newfoundland'},
    {id: 'America/Sao_Paulo', valueUi: '(GMT-03:00) Brasilia'},
    {id: 'America/Argentina/Buenos_Aires', valueUi: '(GMT-03:00) Buenos Aires, Georgetown'},
    {id: 'America/Godthab', valueUi: '(GMT-03:00) Greenland'},
    {id: 'America/Montevideo', valueUi: '(GMT-03:00) Montevideo'},
    {id: 'America/Noronha', valueUi: '(GMT-02:00) Mid-Atlantic'},
    {id: 'Atlantic/Cape_Verde', valueUi: '(GMT-01:00) Cape Verde Is.'},
    {id: 'Atlantic/Azores', valueUi: '(GMT-01:00) Azores'},
    {id: 'Africa/Casablanca', valueUi: '(GMT+00:00) Casablanca, Monrovia, Reykjavik'},
    {id: 'Etc/Greenwich', valueUi: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London'},
    {id: 'Europe/Amsterdam', valueUi: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'},
    {id: 'Europe/Belgrade', valueUi: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'},
    {id: 'Europe/Brussels', valueUi: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris'},
    {id: 'Europe/Sarajevo', valueUi: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb'},
    {id: 'Africa/Lagos', valueUi: '(GMT+01:00) West Central Africa'},
    {id: 'Asia/Amman', valueUi: '(GMT+02:00) Amman'},
    {id: 'Europe/Athens', valueUi: '(GMT+02:00) Athens, Bucharest, Istanbul'},
    {id: 'Asia/Beirut', valueUi: '(GMT+02:00) Beirut'},
    {id: 'Africa/Cairo', valueUi: '(GMT+02:00) Cairo'},
    {id: 'Africa/Harare', valueUi: '(GMT+02:00) Harare, Pretoria'},
    {id: 'Europe/Helsinki', valueUi: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'},
    {id: 'Asia/Jerusalem', valueUi: '(GMT+02:00) Jerusalem'},
    {id: 'Europe/Minsk', valueUi: '(GMT+02:00) Minsk'},
    {id: 'Africa/Windhoek', valueUi: '(GMT+02:00) Windhoek'},
    {id: 'Asia/Kuwait', valueUi: '(GMT+03:00) Kuwait, Riyadh, Baghdad'},
    {id: 'Europe/Moscow', valueUi: '(GMT+03:00) Moscow, St. Petersburg, Volgograd'},
    {id: 'Africa/Nairobi', valueUi: '(GMT+03:00) Nairobi'},
    {id: 'Asia/Tbilisi', valueUi: '(GMT+03:00) Tbilisi'},
    {id: 'Asia/Tehran', valueUi: '(GMT+03:30) Tehran'},
    {id: 'Asia/Muscat', valueUi: '(GMT+04:00) Abu Dhabi, Muscat'},
    {id: 'Asia/Baku', valueUi: '(GMT+04:00) Baku'},
    {id: 'Asia/Yerevan', valueUi: '(GMT+04:00) Yerevan'},
    {id: 'Asia/Kabul', valueUi: '(GMT+04:30) Kabul'},
    {id: 'Asia/Yekaterinburg', valueUi: '(GMT+05:00) Yekaterinburg'},
    {id: 'Asia/Karachi', valueUi: '(GMT+05:00) Islamabad, Karachi, Tashkent'},
    {id: 'Asia/Calcutta', valueUi: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi'},
    {id: 'Asia/Calcutta', valueUi: '(GMT+05:30) Sri Jayawardenapura'},
    {id: 'Asia/Katmandu', valueUi: '(GMT+05:45) Kathmandu'},
    {id: 'Asia/Almaty', valueUi: '(GMT+06:00) Almaty, Novosibirsk'},
    {id: 'Asia/Dhaka', valueUi: '(GMT+06:00) Astana, Dhaka'},
    {id: 'Asia/Rangoon', valueUi: '(GMT+06:30) Yangon (Rangoon)'},
    {id: 'Asia/Bangkok', valueUi: '(GMT+07:00) Bangkok, Hanoi, Jakarta'},
    {id: 'Asia/Krasnoyarsk', valueUi: '(GMT+07:00) Krasnoyarsk'},
    {id: 'Asia/Hong_Kong', valueUi: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi'},
    {id: 'Asia/Kuala_Lumpur', valueUi: '(GMT+08:00) Kuala Lumpur, Singapore'},
    {id: 'Asia/Irkutsk', valueUi: '(GMT+08:00) Irkutsk, Ulaan Bataar'},
    {id: 'Australia/Perth', valueUi: '(GMT+08:00) Perth'},
    {id: 'Asia/Taipei', valueUi: '(GMT+08:00) Taipei'},
    {id: 'Asia/Tokyo', valueUi: '(GMT+09:00) Osaka, Sapporo, Tokyo'},
    {id: 'Asia/Seoul', valueUi: '(GMT+09:00) Seoul'},
    {id: 'Asia/Yakutsk', valueUi: '(GMT+09:00) Yakutsk'},
    {id: 'Australia/Adelaide', valueUi: '(GMT+09:30) Adelaide'},
    {id: 'Australia/Darwin', valueUi: '(GMT+09:30) Darwin'},
    {id: 'Australia/Brisbane', valueUi: '(GMT+10:00) Brisbane'},
    {id: 'Australia/Canberra', valueUi: '(GMT+10:00) Canberra, Melbourne, Sydney'},
    {id: 'Australia/Hobart', valueUi: '(GMT+10:00) Hobart'},
    {id: 'Pacific/Guam', valueUi: '(GMT+10:00) Guam, Port Moresby'},
    {id: 'Asia/Vladivostok', valueUi: '(GMT+10:00) Vladivostok'},
    {id: 'Asia/Magadan', valueUi: '(GMT+11:00) Magadan, Solomon Is., New Caledonia'},
    {id: 'Pacific/Auckland', valueUi: '(GMT+12:00) Auckland, Wellington'},
    {id: 'Pacific/Fiji', valueUi: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.'},
    {id: 'Pacific/Tongatapu', valueUi: '(GMT+13:00) Nuku\'alofa'},
];
export interface IBaseEntity {
  createdAt?: string;
  updatedAt?: string;
}

export interface IExistingEntity extends IBaseEntity {
  createdAt: string;
  updatedAt: string;
}

export interface IHasId {
  id: number;
}

export interface ISelectList<T> {
  id: T;
  valueUi: string;
}

export function StringCapitalizer(str: string): string {
  return str[0] + str.toLowerCase().slice(1);
}

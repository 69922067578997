import { ITokenResponse, ILogin, ELocalStorage, ETokenUrl, IEmailRequestBody, IChangePasswordRequest } from 'app/shared/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { HandleDoubleRequestAbstractClass } from '../classes/handle-double-reques-abstract.class';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthService extends HandleDoubleRequestAbstractClass {
  private logoutSubject = new Subject();
  private timerId: ReturnType<typeof setTimeout>;
  private resetPasswordUrl = `${environment.baseUrl}/user/reset-password`;

  constructor(public http: HttpClient, private router: Router) {
    super();
  }

  public login(value: ILogin) {
    const link = `${environment.authUrl}/${ETokenUrl.TOKEN}`;
    return this.checkDoubleRequest(link, this.http.post(link, value))
      .pipe(tap((response: ITokenResponse) => this.setData(response)));
  }

  public refresh() {
    return this.http.post(`${environment.authUrl}/${ETokenUrl.REFRESH}`, this.refreshToken)
    .pipe(tap((response: ITokenResponse) => this.setData(response)));
  }

  public fogotPassword(body: IEmailRequestBody) {
    return this.checkDoubleRequest(this.resetPasswordUrl, this.http.post(this.resetPasswordUrl, body));
  }

  public resetPassword(resetData: IChangePasswordRequest) {
    return this.checkDoubleRequest(this.resetPasswordUrl, this.http.put(this.resetPasswordUrl, resetData));
  }

  private get refreshToken(): string {
    return localStorage.getItem(ELocalStorage.REFRESH_TOKEN);
  }

  private set refreshToken(value: string) {
    localStorage.setItem(ELocalStorage.REFRESH_TOKEN, value);
  }

  public get token(): string {
    return localStorage.getItem(ELocalStorage.TOKEN);
  }

  public set token(value: string) {
    localStorage.setItem(ELocalStorage.TOKEN, value);
  }

  public logout() {
    this.logoutSubject.next();
    localStorage.removeItem(ELocalStorage.TOKEN);
    localStorage.removeItem(ELocalStorage.REFRESH_TOKEN);
    this.router.navigate(['/login']);
  }

  public onLogout(): Observable<any> {
    return this.logoutSubject.asObservable();
  }

  private setData(response: ITokenResponse) {
    try {
      this.refreshToken = response.refreshToken;
      this.token = response.token;
      this.tokenExpirationHandler();
    } catch (error) {
      console.warn(error);
    }
  }

  private tokenExpirationHandler() {
    try {
      clearTimeout(this.timerId);
      const time = JSON.parse(atob(this.refreshToken.split('.')[1])).exp * 1000 - new Date().getTime() - 100;
      this.timerId = setTimeout(_ => this.logout(), time);
    } catch (error) {
      console.warn(error);
    }
  }
}
